import React, { useEffect, Fragment } from "react"

import { useTheme } from "../../hooks/useTheme"

import SEO from "../seo"
import FullSection from "../page-blocks/fullSection"
import GridSection from "../page-blocks/gridSection"

import PageDisplay from "./PageDisplay"

const PageContainer = ({ content, location }) => {
  const { title, headline, _rawPageSections, seo } = content
  const { theme, setTheme } = useTheme()

  useEffect(() => {
    setTheme("white")
  }, [setTheme])
  const sections = _rawPageSections.map(({ _type, _key, ...props }, index) => {
    switch (_type) {
      case "fullSection":
        return <FullSection firstBlock={index === 0} key={_key} {...props} />
      case "gridSection":
        return (
          <GridSection
            theme={theme}
            firstBlock={index === 0}
            key={_key}
            {...props}
          />
        )
      default:
        return <div key={`undefined-${index}`}>not supported block type</div>
    }
  })

  return (
    <Fragment>
      <SEO {...seo} title={title || seo.title} />
      <div className={headline == 'Get in touch.'?'contact':'' }>
        <PageDisplay
          location={location}
          sections={sections}
          title={title}
          headline={headline}
          theme={theme}
        />
      </div>
    </Fragment>
  )
}

export default PageContainer
