import React from "react"
import { useSpring, config } from "react-spring"

import { Document, MainTitle, Header, InnerFooter, PageWrapper } from "./styles"
import SeagullsIsoSvg from "../icons/SeagullsIso.svg"

const PageDisplay = ({ sections, theme, title, headline, location }) => {
  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: config.slow,
  })
  return (
    <PageWrapper theme={theme} style={props}>
      <Document theme={theme}>
        <Header>
          <MainTitle theme={theme}>{headline || title}</MainTitle>
        </Header>
        {sections}
      </Document>
      {location.pathname === "/about" && (
        <InnerFooter>
          <SeagullsIsoSvg />
        </InnerFooter>
      )}
    </PageWrapper>
  )
}

export default PageDisplay
