import React from "react"
import { graphql } from "gatsby"
import PageContainer from "../components/Page"

export const query = graphql`
  query PageTemplateQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      headline
      title
      _rawPageSections(resolveReferences: { maxDepth: 14 })
      seo {
        ...SEO
      }
    }
  }
`

const Page = ({ data, location }) => {
  return <PageContainer content={data.page} location={location} />
}

export default Page
