import styled from "@emotion/styled"

import { colors, fixedValues } from "../../styles/variables.styles"
import {
  page_headline,
  headline3,
  PageTitle,
} from "../../styles/typography.styles"

import { wrapper } from "../../styles/shared.styles"
import { animated } from "react-spring"
import { mq } from "../../styles/helpers.styles"

export const PageWrapper = styled(animated.section)`
  padding-top: ${`calc(${fixedValues.headerHeightMobile} + 5vh)`};
  padding-bottom: ${fixedValues.headerHeight};
  background: ${({ theme }) =>
    theme === "white" ? "#FFF" : colors.primary.blue};
  ${mq.laptop} {
    padding-top: ${`calc(${fixedValues.largeHeaderHeight} + 5vh)`};
  }
  ${mq.desktop__l} {
    padding-top: ${`calc(${fixedValues.largeHeaderHeight} + 5vh)`};
  }
`

export const Header = styled.header`
  ${wrapper};
  margin-bottom: 30px;
  ${mq.laptop} {
    margin-bottom: 60px;
  }
`
export const MainTitle = styled(PageTitle)`
  color: ${({ theme }) => (theme === "white" ? colors.primary.blue : "#FFF")};
`

export const Document = styled.div`
  .link {
    display: inline-block;
    color: ${({ theme }) => (theme === "white" ? "#000" : "#FFF")};
    transition: color 200ms ease-in-out;
    text-decoration: underline;
    &:hover {
      color: ${({ theme }) =>
        theme === "white" ? colors.primary.blue : "#000"};
      text-decoration: none;
    }
  }
  h2 {
    ${page_headline};
    margin-bottom: 0.5em;
  }

  h3 {
    ${headline3};
  }

  ul {
    list-style: initial;
    padding: 0em 1em;
    ${mq.desktop} {
      padding: 0;
    }
  }

  p {
    margin: 1em 0;
  }
`

export const InnerFooter = styled.footer`
  ${wrapper}
  svg {
    height: 80px;
    width: auto;
  }
`
