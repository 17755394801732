import React from "react"
import PortableText from "../PortableText"
import Gallery from "../Gallery"
import { Block, GridWrapper, Body, Media } from "./styles"

const GridSection = ({ body, media, firstBlock = false, layout }) => {
  return (
    <Block firstBlock={firstBlock}>
      <GridWrapper layout={layout}>
        {body && (
          <Body layout={layout}>
            <PortableText blocks={body} />
          </Body>
        )}
        {media && (
          <Media layout={layout}>
            <Gallery slides={media} />
          </Media>
        )}
      </GridWrapper>
    </Block>
  )
}

export default GridSection
