import React from "react"
import PortableText from "../PortableText"
import { Block, SectionWrapper } from "./styles"
import { BodyText } from "../../styles/typography.styles"

const FullSection = ({ text, firstBlock = false }) => {
  return (
    <Block firstBlock={firstBlock}>
      <SectionWrapper>
        <BodyText>
          <PortableText blocks={text} />
        </BodyText>
      </SectionWrapper>
    </Block>
  )
}

export default FullSection
