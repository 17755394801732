import styled from "@emotion/styled"

import { wrapper } from "../../styles/shared.styles"
import { BodyText } from "../../styles/typography.styles"
import { mq } from "../../styles/helpers.styles"

export const Block = styled.div`
  ${wrapper}
  margin: 30px auto 60px;
  &:last-child {
    margin-bottom: 0px;
  }
  ${mq.tablet} {
    margin: 0 auto 90px;
  }
  ${mq.laptop} {
    margin: 0 auto 120px;
  }
`

export const GridWrapper = styled.div`
  ${mq.desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
  }
  > * {
    align-self: start;
    grid-row: 1;
  }
`

export const Body = styled(BodyText)`
  grid-column: ${({ layout }) =>
    layout && layout === "text-image" ? "1 / 2" : " 2 / 3"};
  .headline {
    margin-bottom: 0.5em;
  }
  ${mq.desktop} {
    padding-top: 60px;
  }
`

export const Media = styled(BodyText)`
  grid-column: ${({ layout }) =>
    layout && layout === "text-image" ? "2 / 3" : "1 / 2"};
  box-shadow: 0px 5px 10px -8px #000;
  margin-top: 30px;
  ${mq.desktop} {
    margin-top: 0px;
  }
`

export const SectionWrapper = styled.div``
